<script>
export default {
    name: 'FileBrowser',

    props: {
        filesTree: {
            type: Array,
            required: true,
        },

        isLoadingFile: {
            type: Boolean,
            required: false,
        },

        loadIotDeviceCtrl: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            fileIconKeys: [],
            fileBrowserIndent: 0.5,
        };
    },

    render(createElement) {
        const createFile = ([hierarchyKey, hierarchyValue], pathPadding) => {
            const vDomNodes = createElement('span', {
                class: 'file__name flex relative',
                style: `padding-left: ${pathPadding * this.fileBrowserIndent}rem`,
                attrs: {
                    'data-path': hierarchyValue.path,
                },
                on: {
                    click: this.handleOpenFile,
                },
            }, [
                createElement('FileName', {
                    props: {
                        filePath: hierarchyValue.path,
                        fileName: hierarchyKey,
                    },
                    class: 'flex flex-1 items-center relative z-10 text-gray-300 cursor-pointer py-1 pr-2 text-xs',
                }),
            ]);

            return vDomNodes;
        };

        const createTree = ([hierarchyKey, hierarchyValue], pathPadding) => {
            let vDomNodes;
            if (hierarchyValue.path) {
                vDomNodes = createFile([hierarchyKey, hierarchyValue], pathPadding);
            } else {
                vDomNodes = createElement('div', {
                    class: 'folder flex flex-col',
                    on: {
                        click: this.handleFolderClick,
                    },
                }, [
                    createElement('span', {
                        class: 'folder__name flex py-1 py-2 items-center text-gray-300 text-sm relative cursor-pointer',
                        style: `padding-left: ${pathPadding * this.fileBrowserIndent}rem;`,
                    }, [
                        createElement(
                            'folderClosedSvg',
                            {
                                class: 'folder__icon folder__icon--closed h-4 mr-2 pointer-events-none',
                            },
                        ),
                        createElement(
                            'folderOpenedSvg',
                            {
                                class: 'folder__icon folder__icon--opened h-4 mr-2 pointer-events-none',
                            },
                        ),
                        hierarchyKey,
                    ]),
                    createElement('div', {
                        class: 'folder__content',
                    }, [
                        Object.entries(hierarchyValue)
                            .map(([folderHierarchyKey, folderHierarchyValue]) => createTree([
                                folderHierarchyKey,
                                folderHierarchyValue,
                            ], pathPadding + 1)),
                    ]),
                ]);
            }

            return vDomNodes;
        };

        return createElement(
            'div',
            {
                ref: 'fileBrowser',
                class: 'folder__root flex flex-1 flex-col bg-ide-file-browser-gray',
            },
            [
                createElement(
                    'div',
                    {
                        class: 'h-10 px-2 text-gray-400 text-xs uppercase tracking-wide flex flex-shrink-0 items-center justify-between',
                    },
                    [
                        createElement('span', ['Explorer']),
                        createElement(
                            'div',
                            {
                                class: 'flex justify-end',
                            },
                            [
                                ...this.isLoadingFile ? [createElement('AppLoader', {
                                    class: 'h-4 stroke-current',
                                })] : [],
                                createElement(
                                    'button',
                                    {
                                        class: 'fold-btn text-ide-dock cursor-pointer transition duration-200 ease-in-out hover:text-gray-100 px-2 outline-none focus:outline-none opacity-0 pointer-events-none',
                                        on: {
                                            click: this.onFoldExplorer,
                                        },
                                    },
                                    [
                                        createElement(
                                            'foldSvg',
                                            {
                                                class: 'h-4 fill-current',
                                            },
                                        ),
                                    ],
                                ),
                            ],
                        ),
                    ],
                ),
                createElement('div', {
                    class: 'flex flex-1 overflow-y-auto flex-col',
                }, [
                    Object.entries(this.hierarchy)
                        .map(([hierarchyKey, hierarchyValue]) => createTree([
                            hierarchyKey,
                            hierarchyValue,
                        ], 1)),
                ]),
                ...this.loadIotDeviceCtrl ? [createElement('IotDeviceCtrl', {
                    class: 'h-12 flex flex-shrink-0 items-center justify-between',
                })] : [],
            ],
        );
    },

    computed: {
        hierarchy() {
            return this.filesTree.reduce((hier, path) => {
                let x = hier;
                path.split('/').forEach((item) => {
                    if (!x[item]) {
                        x[item] = {};
                    }
                    x = x[item];
                });
                x.path = path;
                return hier;
            }, {});
        },
    },

    methods: {
        handleOpenFile(evt) {
            this.deactivateFiles();
            const fileRootEl = evt.target.parentElement;
            fileRootEl.classList.add('active');
            this.$emit('open-file', fileRootEl.dataset.path);
        },

        deactivateFiles() {
            const allFiles = this.$refs.fileBrowser.querySelectorAll('.file__name');
            for (let i = 0; i < allFiles.length; i += 1) {
                allFiles[i].classList.remove('active');
            }
        },

        onFoldExplorer() {
            const allFolders = this.$refs.fileBrowser.querySelectorAll('.folder');
            for (let i = 0; i < allFolders.length; i += 1) {
                allFolders[i].classList.remove('open');
            }
        },

        handleFolderClick(evt) {
            evt.stopImmediatePropagation();
            evt.target.parentElement.classList.toggle('open');
        },
    },

    components: {
        AppLoader: () => import('@/components/ui/AppLoader.vue'),
        FileName: () => import('./FileName.vue'),
        folderClosedSvg: () => import('@/assets/img/vscodeIcons/default_folder.svg'),
        folderOpenedSvg: () => import('@/assets/img/vscodeIcons/default_folder_opened.svg'),
        foldSvg: () => import('@/assets/img/fold.svg'),
        IotDeviceCtrl: () => import('@/components/tutorials/ide/IotDeviceCtrl.vue'),
    },
};
</script>

<style lang="postcss" scoped>
.folder__root:hover .fold-btn {
    opacity: 1;
    pointer-events: auto;
}

.folder > .folder__name .folder__icon--opened {
    display: none;
}

.folder.open > .folder__name .folder__icon--closed {
    display: none;
}

.folder.open > .folder__name .folder__icon--opened {
    display: inline;
}

.folder > .folder__name .folder__icon--closed {
    display: inline;
}

.folder__content {
    display: none;
}

.folder.open > .folder__content {
    display: block;
}

.file__name {
    &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        z-index: 0;
        opacity: 0;
        background-color: theme('colors.orange.500');
    }

    &:hover {
        background-color: theme('backgroundColor.ide-file-light');

        &::before {
            opacity: .8;
        }
    }

    &.active {
        background-color: theme('backgroundColor.ide-file');

        &::before {
            opacity: .8;
        }
    }
}

.folder__name:hover {
    background-color: theme('backgroundColor.ide-file-light');
}
</style>
